<template>
  <div class="list-point">
    <div class="list-table">
      <div class="mb-2 d-flex justify-content-between">
        <h2 class="w-75">Q＆Aを一覧表示</h2>
      </div>
      <Tables :items="listQA" :fields="fields" :itemsPerPage="10">
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <CButton
              class="mx-1"
              v-b-popover.hover.top="'詳細'"
              color="info"
              square
              v-on:click="detailQA(item.id)"
              v-b-modal.modal-detail
              variant="outline"
              size="sm"
            >
              <b-icon icon="eye" aria-hidden="true"></b-icon>
            </CButton>
          </td>
        </template>
      </Tables>
    </div>

    <b-modal id="modal-detail" hide-header hide-footer>
      <div class="d-block text-center">
        <h5>{{ qaById.subject }}</h5>
      </div>
      <div>
        <CContainer>
          <CRow>
            <CCol lg="3" class="py-3">Name:</CCol>
            <CCol lg="9" class="py-3">{{ qaById.name }}</CCol>
          </CRow>
          <CRow>
            <CCol lg="3" class="py-3">Email:</CCol>
            <CCol lg="9" class="py-3">{{ qaById.email }}</CCol>
          </CRow>
          <CRow>
            <CCol lg="3" class="py-3">Message:</CCol>
            <CCol lg="9" class="py-3">{{ qaById.message }}</CCol>
          </CRow>
          <CRow>
            <CCol lg="3" class="py-3">Create At:</CCol>
            <CCol lg="9" class="py-3">{{
              new Date(qaById.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}</CCol>
          </CRow>
        </CContainer>
      </div>
    </b-modal>
    <!-- Modal Detail Q&A -->
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ListQA",
  components: {
    Tables,
  },
  data() {
    return {
      fields: tableFields.QA,
      dataModal: "",
    };
  },
  created() {
    const { shopId } = this.$route.params;
    this.getListQA(shopId);
  },
  computed: {
    ...mapGetters(["listQA", "message", "success", "error", "qaById"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
  },
  methods: {
    ...mapActions({ getListQA: "getListQA" }),
    ...mapActions({ getQAById: "getQAById" }),

    detailQA(id) {
      this.dataModal = id;
      this.getQAById(id);
    },
  },
};
</script>
